$yellow: #F6ED57;
$yellowAlpha: rgba(246, 239, 71, 0.8);

* {
  box-sizing: border-box;
}

html, body {
  padding: 0px;
  margin: 0px;
  height: 100%;
  font-family: 'Poppins', sans-serif;
  color: #000;
  background: $yellow;
  scroll-behavior: smooth;
}

.all {
  position: relative;
  width: 100%;
  height: 100vh;

  &-touching {
    background: #f00;

    .happy {
      display: none;
    }

    .sad {
      display: inline-block !important;
    }
  }
}

.container {
  display: flex;
  position: absolute;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;

  .center {
    width: 640px;
  }

  .center-all {
  }
}

.below-the-fold {
  background: #000;
  color: #fff;
}

.touching {
  background: url(img/biohazard.png) center center no-repeat $yellowAlpha;
  background-size: 50%;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 72px;
  display: none;
  color: #fff;
  font-family: monospace;
}

.detector {
  position: relative;
  width: 640px;
  height: 480px;
  background: #000;

  video, canvas {
    width: 640px;
    height: 480px;
    position: absolute;
    border-radius: 50%;
    overlfow: hidden;
  }

  &-no {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 9;
    background-color: rgba(0,0,0,0.8);
    text-align: center;
    width: 100%;
    height: 100%;
    color: #f00;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 240px;
    font-weight: 700;
    font-style: italic;
  }
}

.hidden-canvas {
  display: block;
}

.bottom, h1 {
  text-align: center;
}

.bottom a {
  display: inline-block;
  margin-top: 40px;
  color: #f00;
}

.loader,
.loader:after {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin-top: 10px;
}

.loader {
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 10px solid rgba(0, 0, 0, 1);
  border-right: 10px solid rgba(0, 0, 0, 1);
  border-bottom: 10px solid rgba(0, 0, 0, 1);
  border-left: 10px solid $yellow;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}

@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.title {
  color: #000;
  font-size: 42px;
  line-height: 0.8;
  text-align: center;
  margin-bottom: 20px;
}

.training-ui {
  bottom: 0px;
  width: 100%;
  height: 80px;
  background: $yellow;
  color: #000;
  border: 2px solid #000;
  position: absolute;
  transition: 500ms all;

  &-red {
    background: #f00;
  }

  .left, .right {
    height: 100%;
    float: left;
    display: flex;
    align-items: center;
  }

  .left {
    width: 426px;
    padding-left: 20px;
    font-weight: 700;
  }

  .right {
    width: 210px;
    justify-content: center;
  }

  &-center {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  button {
    width: 150px;
    height: 50px;
    font-family: 'Poppins', sans-serif;
    outline: none;
    border: 0;
    background: #000;
    color: $yellow;
    font-size: 18px;
    cursor: pointer;
    transition: 300ms all;

    &:hover {
      background: #333;
    }
  }

  .countdown {
    width: 150px;
    height: 50px;
    background: #000;
    display: none;
    align-items: center;

    div {
      color: $yellow;
      font-weight: 700;
      flex: 1 0 21%; /* explanation below */
      opacity: 0.5;
      transition: 250ms;
      text-align: center;
    }

    div:nth-of-type(1) {
      opacity: 1;
    }

    img {
      width: 24px;
    }
  }

  .countdown3 {
    div:nth-of-type(1) {
      opacity: 1;
    }
  }

  .countdown2 {
    div:nth-of-type(1),div:nth-of-type(2) {
      opacity: 1;
    }
  }

  .countdown1 {
    div:nth-of-type(1), div:nth-of-type(2), div:nth-of-type(3), {
      opacity: 1;
    }
  }

  .bar {
    width: 150px;
    height: 50px;
    background: #000;
    border: 2px solid #000;
    display: none;
    position: relative;

    div {
      height: 100%;
      width: 0%;
      position: absolute;
      left: 0px;
      top: 0px;
      background: $yellow;
    }
  }

  .item-active-flex {
    display: flex;
  }

  .item-active {
    display: block;
  }

  &-hide {
    display: none;
  }
}

.hand-gif {
  position: absolute;
  bottom: 90px;
  right: 10px;
  z-index: 9;
  width: 100px;
}

.below {
  background: #000;
  color: #fff;
  padding: 40px;

  &-container {
    max-width: 600px;
    margin: 0 auto;
  }

  p {
    font-family: helvetica;
    line-height: 1.5;
  }

  strong {
    display: block;
  }

  a {
    color: $yellow;

    &:hover {
      color: #ccc;
    }
  }

  h2 {
    font-size: 32px;
    line-height: 48px;
    text-transform: uppercase;
    color: $yellow;
    font-style: italic;
  }
}

.about-btn {
  position: absolute;
  bottom: 10px;
  right: 10px;
  text-align: center;

  a {
    text-transform: uppercase;
    color: #000;
    font-weight: 700;
    transition: 500ms opacity;
    font-size: 14px;

    &:hover {
      color: #333;
    }
  }
}

.mute-btn {
  position: absolute;
  top: 10px;
  right: 10px;

  button {
    border: 0;
    outline: 0;
    background: none;
    cursor: pointer;
  }
}


.mobile {
  height: 100%;
  text-transform: uppercase;
  line-height: 1;
  font-size: 14px;

  h1 {
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    font-style: italic;
  }

  div {
    margin: 100px 40px;
    text-align: center;
  }
}

.area-top, .area-right, .area-bottom, .area-left {
  position: absolute;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;

  .happy, .sad {
    width: 160px;
    height: 160px;
  }

  .sad {
    display: none;
  }

  .bubble {
    width: 200px;
  }

  .img-wrapper {
    width: 100%;
    position: absolute;
  }

  .img-wrapper-top {
    top: 20px;
  }

  .img-wrapper-bottom {
    bottom: 20px;
  }
}

.area-top {
  width: 100%;
  height: calc((100vh - 480px) / 2);
  top: 0px;
  left: 0px;
}

.area-right {
  height: 100%;
  width: calc((100vw - 640px) / 2);
  right: 0px;
  top: 0px;


  .big-text {
    transform: rotate(90deg);
  }
}

.area-left {
  height: 100%;
  width: calc((100vw - 640px) / 2);
  left: 0px;
  top: 0px;

  .big-text {
    transform: rotate(-90deg);
  }
}

.area-bottom {
  width: 100%;
  height: calc((100vh - 480px) / 2);
  bottom: 0px;
  left: 0px;

  .big-text {
    transform: rotate(180deg);
  }
}

.big-text {
  font-size: 140px;
  font-weight: 200;
  font-style: italic;
  text-transform: uppercase;
  line-height: 1;
  user-select: none;
  display: inline-block;
}
